"use client";

import { Button, Container, Group, Stack, Text, Title } from "@mantine/core";
import { useEffect } from "react";
import { KiwiTicketLogo } from "~/components/KiwiTicketLogo";
import * as Sentry from "@sentry/nextjs";

// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <Container size="md">
      <Stack justify="center" align="center" h="100vh">
        <KiwiTicketLogo />

        <Title style={{ textAlign: "center" }}>Something unexpected happened</Title>
        <Text size="md" style={{ textAlign: "center" }}>
          We were unable to process your request, please refresh the page and try again.
          <br />
          Our team has been notified of the issue.
        </Text>

        <Group justify="center">
          <Button onClick={() => reset()}>Try again</Button>
        </Group>
      </Stack>
    </Container>
  );
}
